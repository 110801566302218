<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">商品信息</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="viewC">
				<div class="viewTitle">基本信息</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">条形码:</div>
						<el-input v-model="barCode" placeholder="请输入条形码" style="width: 200px;" @blur="handelBarCodeBlur" clearable
							:disabled="barCodeAbled"></el-input>
					</div>
					<el-button type="primary" style="margin-left: 12px;" @click="handleAddBarCodeClick"
						v-if="editMsg == null">生成条形码</el-button>
					<el-button style="margin-left: 12px;" @click="handleCancelCode"
						v-if="barCodeAbled && editMsg == null">取消生成</el-button>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>商品名称:</div>
						<el-input v-model="title" placeholder="请输入商品名称" style="width: 200px;" @input="handleInput"></el-input>
					</div>
					<div class="comItem">
						<div class="comItemName">简码:</div>
						<el-input v-model="simpleCode" placeholder="请输入简码" style="width: 200px;"
							:disabled="simpleCodeAbled"></el-input>
						<el-tooltip class="item" effect="dark"
							content="例:如佩玛思特，简码设置为pm就可以直接搜索到这个商品，一个商品可多个简码，输入框顿号分隔。如佩玛思特为pm、st、pmst" placement="bottom">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>品牌:</div>
						<el-select v-model="brand" filterable placeholder="请选择品牌" style="width: 200px;" clearable>
							<el-option v-for="item in brandList.itemList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="comTxt">(没找到品牌?请<span @click="handleAddClassClick(4)">新增品牌</span>)</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>商品分类:</div>
						<el-select v-model="firstClass" placeholder="请选择一级分类" style="width: 200px;" clearable
							@change="handleFirstClassChange">
							<el-option v-for="item in firstClassList.itemList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
						<el-select v-model="secondClass" placeholder="请选择二级分类" style="width: 200px;margin-left: 12px;" clearable
							v-if="firstClass != ''">
							<el-option v-for="item in secondClassList.itemList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="comTxt">(没找到分类?请<span @click="handleAddClassClick()">新增分类</span>)</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>适用品种:</div>
						<el-radio-group v-model="applyType">
							<el-radio :label="''">通用</el-radio>
							<el-radio :label="'cat'">猫猫</el-radio>
							<el-radio :label="'dog'">狗狗</el-radio>
							<el-radio :label="'yichong'">其他</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>规格型号:</div>
						<el-input v-model="model" placeholder="请输入规格型号" style="width: 200px;"></el-input>
					</div>
					<div class="comTxt">例如10kg/袋</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">保质期:</div>
						<el-input type="number" v-model.number="qaDays" placeholder="请输入保质期/天" style="width: 200px;"></el-input>
					</div>
				</div>
				<div class="viewTitle">售卖信息</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">零售价:</div>
						<el-input type="number" v-model.number="salePrice" placeholder="请输入零售价" style="width: 200px;"></el-input>
					</div>
				</div>
				<div class="viewTitle">高级设置</div>
				<div class="comView" v-if="editMsg == null">
					<div class="comItem">
						<el-checkbox-group v-model="initStockCheck" @change="handleStockChange">
							<el-checkbox>新建初始库存</el-checkbox>
						</el-checkbox-group>
					</div>
					<div class="comItem" v-if="initStockCheck">
						<div class="comItemName">初始库存:</div>
						<el-input type="number" v-model.number="initStock.count" min="0" step="1" placeholder="请输入初始库存"
							style="width: 200px;"></el-input>
					</div>
					<div class="comItem" v-if="initStockCheck">
						<div class="comItemName">进货总价:</div>
						<el-input type="number" v-model.number="initStock.totalAmount" min="0" step="0.01" placeholder="请输入进货总价"
							style="width: 200px;"></el-input>
					</div>
					<div class="comItem" v-if="initStockCheck">
						<div class="comItemName">供应商:</div>
						<el-select v-model="initStock.supplierId" placeholder="请选择供应商" style="width: 200px;">
							<el-option v-for="item in supplierList.itemList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="comTxt" v-if="initStockCheck">(没找到供应商?请<span @click="handleAddClassClick(5)">新增供应商</span>)</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<el-checkbox v-model="enableNoStockSale">支持负库存销售</el-checkbox>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<el-checkbox v-model="enableStockAlert">库存预警提醒</el-checkbox>
					</div>
					<div class="comItem" v-if="enableStockAlert">
						<div class="comItemName">库存少于</div>
						<el-input type="number" v-model.number="minStock" min="0" step="1" placeholder="请输入库存"
							style="width: 200px;"></el-input>
						<div>个提醒</div>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<el-checkbox v-model="buyBack">复购提醒</el-checkbox>
					</div>
				</div>
				<div class="comView">
					<div class="comItem" style="align-items: initial;height: auto;">
						<div class="comItemName">商品图片:</div>
						<el-upload ref="fileUpload" :http-request="getUploadImg" :multiple="false" list-type="picture-card"
							:auto-upload="true" :show-file-list="false" action="" class="cropper-upload-box">
							<div
								style="display: flex;flex-direction: column;align-items: center;justify-content: center;overflow: hidden;">
								<img v-if="imgUrl1" :src="imgUrl1" class="avatar">
								<i v-else class="el-icon-plus"></i>
							</div>
						</el-upload>
					</div>
				</div>
			</div>

			<div class="viewB">
				<el-button style="width: 110px;" v-if="editMsg != null" @click="printCustom(barCode)">打印条码</el-button>
				<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交</el-button>
			</div>
		</div>
		<div v-if="dialogTypeSetting_state">
			<dialogTypeSetting @submit="submit_dialogTypeSetting" :addType="addType" :nodeData="nodeData">
			</dialogTypeSetting>
		</div>
	</div>
</template>

<script>
import { pinyin } from 'pinyin-pro';
import { put } from '@/api/upload.js'
import dialogTypeSetting from "../../../components/dialogTypeSetting.vue"
import printJs from "../../../util/print.js";
export default {
	components: {
		dialogTypeSetting
	},
	data() {
		return {
			editMsg: JSON.parse(this.$route.query.editMsg),  //为null是新增  编辑跳转信息
			barCode: '', //条码
			barCodeAbled: false,  //条码是否禁填
			title: '', //商品名称
			simpleCode: '', //简码
			simpleCodeAbled: false,  //简码是否禁用
			brand: '', //品牌
			brandList: [],  //品牌列表
			applyType: '', //适用品种
			model: '', //自定义规格
			qaDays: '', //保质期
			salePrice: '', //零售价
			imgUrl1: '', //图片
			firstClass: '', //一级分类
			firstClassList: {},  //一级分类列表
			secondClass: '',  //二级分类
			secondClassList: {},  //二级分类列表
			enableStockAlert: false, //库存预警提醒
			minStock: '', //最小库存
			buyBack: false, //复购提醒
			enableNoStockSale: false, //负库存销售
			initStockCheck: false,  //新建初始化库存
			initStock: { //初始库存
				count: '', //初始库存
				totalAmount: '', //总价
				supplierId: '', //供应商编号
				supplierName: '',  //供应商名字
			},
			supplierList: {},  //供应商列表
			cover: '',  //商品图片
			addType: 0,  //弹框类型
			nodeData: {},  //弹框内容
			dialogTypeSetting_state: false, //弹框状态
		}
	},
	mounted() {
		this.getBrandData()
		this.getProductData()
		if (this.editMsg != null) {
			this.barCode = this.editMsg.barCode;
			this.barCodeAbled = true;
			this.title = this.editMsg.title;
			this.simpleCode = this.editMsg.simpleCode;
			this.simpleCodeAbled = true;
			this.brand = this.editMsg.brand.id;
			this.firstClass = this.editMsg.firstCategoryInfo.id;
			this.secondClass = this.editMsg.secondCategoryInfo.id;
			this.applyType = this.editMsg.applyType;
			this.model = this.editMsg.model;
			this.qaDays = this.editMsg.qaDays;
			this.salePrice = this.editMsg.salePrice;
			this.enableNoStockSale = this.editMsg.enableNoStockSale;
			this.enableStockAlert = this.editMsg.enableStockAlert;
			this.buyBack = this.editMsg.buyBack;
			this.minStock = this.editMsg.minStock;
			this.cover = this.editMsg.cover ? this.editMsg.cover + '?' + "x-oss-process=image/resize,w_300/quality,q_90" : '';
			this.imgUrl1 = this.editMsg.cover ? this.editMsg.cover + '?' + "x-oss-process=image/resize,w_300/quality,q_90" : '';
		}
	},
	methods: {
		printCustom() {
			let params = {
				code: this.barCode,
          name: this.title,
          sku:this.model,
          price:this.salePrice,
			};
			console.log(params, "params");
			printJs.printTicketBarCode(params).then((res) => {
				console.log(res,'res');
				this.$store.commit("set_printUrl", res);
				var hostObject = window.chrome.webview.hostObjects.customWebView2HostObject;
         hostObject.print(res);

			});

		},
		handleInput(event) {
			console.log(event)
			this.simpleCode = this.toPinyin(event).replace(/\s+/g, '')
		},
		toPinyin(str) {
			let pinyinArr = pinyin(str, { pattern: 'initial' })
			return pinyinArr
		},
		//提交
		handleSubmitClick() {
			if (this.barCode == '') {
				this.$message({
					type: 'error',
					message: '请输入条形码'
				});
				return
			}
			if (this.title == '') {
				this.$message({
					type: 'error',
					message: '请输入商品名称'
				});
				return
			}
			if (this.brand == '') {
				this.$message({
					type: 'error',
					message: '请选择品牌'
				});
				return
			}
			if (this.firstClass == '') {
				this.$message({
					type: 'error',
					message: '请选择一级分类'
				});
				return
			}
			if (this.secondClass == '') {
				this.$message({
					type: 'error',
					message: '请选择二级分类'
				});
				return
			}

			let params = {
				barCode: this.barCode,
				applyType: this.applyType + '',
				title: this.title,
				cover: this.cover,
				simpleCode: this.simpleCode,
				model: this.model,
				enableNoStockSale: this.enableNoStockSale,
				enableStockAlert: this.enableStockAlert,
				buyBack: this.buyBack,
			}
			// 零售价
			if (this.salePrice == '') {
				params.salePrice = 0
			} else {
				params.salePrice = this.salePrice
			}
			// 保质期
			if (this.qaDays == '') {
				params.qaDays = 0
			} else {
				params.qaDays = this.qaDays
			}
			//获取品牌参数
			this.brandList.itemList.map((item) => {
				if (item.id == this.brand) {
					params.brand = {
						id: item.id,
						name: item.name
					}
				}
			})
			//获取1级类目参数
			this.firstClassList.itemList.map((item) => {
				if (item.id == this.firstClass) {
					params.firstCategoryInfo = {
						id: item.id,
						name: item.name,
						code: item.code
					}
				}
			})
			//获取2级类目参数
			this.secondClassList.itemList.map((item) => {
				if (item.id == this.secondClass) {
					params.secondCategoryInfo = {
						id: item.id,
						name: item.name,
						code: item.code
					}
				}
			})
			//初始化库存
			if (this.initStockCheck) {
				this.supplierList.itemList.map((item) => {
					if (item.id == this.initStock.supplierId) {
						this.initStock.supplierName = item.name
					}
				})
				params.initStock = this.initStock;
			} else {
				params.initStock = {
					count: 0,
					totalAmount: 0,
					supplierId: '',
					supplierName: ''
				}
			}
			//库存预警提醒 最小库存
			if (this.enableStockAlert) {
				params.minStock = this.minStock;
			} else {
				params.minStock = 0;
			}
			if (this.editMsg != null) {   //编辑
				params.goodsId = this.editMsg.goodsId;
			}
			this.$http.post("/product/goods/update", params).then(res => {
				if (res.code === 0) {
					this.$message({
						type: 'success',
						message: '已提交'
					});
					sessionStorage.setItem('mark', 1)
					this.$router.go(-1);
				}
			})
		},
		//供应商列表
		getSupplierData() {
			let params = {
				code: 'suppliertype'
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					this.supplierList = res.data;
				}
			})
		},
		//新建初始库存变化
		handleStockChange() {
			if (this.initStockCheck) {
				this.getSupplierData()
			}
		},
		//1级类目变化
		handleFirstClassChange() {
			this.secondClass = '';
			this.secondClassList = [];
			this.firstClassList.itemList.map((item) => {
				if (item.id == this.firstClass) {
					this.secondClassList = item
				}
			})
		},
		//获取类目
		getProductData() {
			let params = {
				code: 'goodstype'
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					this.firstClassList = res.data;
					if (this.firstClass != '') {
						this.firstClassList.itemList.map((item) => {
							if (item.id == this.firstClass) {
								this.secondClassList = item
							}
						})
					}
				}
			})
		},
		//关闭弹框
		submit_dialogTypeSetting() {
			if (this.addType == 1) {
				this.getProductData()
			} else if (this.addType == 2) {
				this.getProductData();
			} else if (this.addType == 4) {
				this.getBrandData()
			} else if (this.addType == 5) {
				this.getSupplierData()
			}
			this.dialogTypeSetting_state = false;
		},
		//打开弹框
		handleAddClassClick(addType) {
			if (addType == 4) {
				this.addType = addType;
				this.nodeData = this.brandList;
			} else if (addType == 5) {
				this.addType = addType;
				this.nodeData = this.supplierList;
			} else {
				if (this.firstClass == '') {
					this.addType = 1;
					this.nodeData = this.firstClassList;
				} else {
					this.addType = 2;
					this.nodeData = this.secondClassList;
				}
			}
			this.dialogTypeSetting_state = true;
		},
		//获取品牌
		getBrandData() {
			let params = {
				code: 'breedtype'
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					this.brandList = res.data;
				}
			})
		},
		//取消生成条码
		handleCancelCode() {
			this.barCode = '';
			this.barCodeAbled = false;
		},
		//生成条码
		handleAddBarCodeClick() {
			this.barCode = '';
			this.barCodeAbled = false;
			this.$http.post("/product/goods/code/new", { barcode: this.barCode }).then(res => {
				if (res.code === 0) {
					this.$message({
						type: 'success',
						message: '已生成条码'
					});
					this.barCode = res.data;
					this.barCodeAbled = true;
				}
			})
		},
		//条码框失去焦点
		handelBarCodeBlur() {
			if (this.barCode != '') {
				this.$http.get("/product/goods/code/standard/info", { barcode: this.barCode }).then(res => {
					if (res.code === 0) {
						if (res.data.barCode == null) {
							// this.$message({
							// 	type: 'error',
							// 	message: '该条码无信息，请生成条码'
							// });
							// this.barCode = '';
							console.log(111111111);
						} else {
							this.title = res.data.title;
						}
					}
				})
			}
		},
		//返回
		handleBackClick() {
			this.$confirm('是否返回上一页?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.go(-1);
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		getUploadImg(item) {
			// 随机命名
			var fileName = item.file.name
			var file = item.file // 
			put(fileName, file).then(result => {  // 调oss api 上传图片
				this.cover = result.url;
				this.imgUrl1 = result.url;
				// this.fileList.push(result.name)
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.page {
	width: 100%;
	height: 100%;
	color: $fontColor;

	.view {
		background: #fff;
		height: 100%;

		border-radius: 20px;
		display: flex;
		flex-direction: column;

		.viewT {
			box-sizing: border-box;
			height: 68px;
			display: flex;
			align-items: center;
			border-bottom: solid 1px #F2F2F2;
			padding: 0 20px;
			justify-content: space-between;
		}

		.viewC {
			flex: 1;
			overflow-y: scroll;
			padding: 0 20px;
			padding-top: 20px;

			.viewTitle {
				width: 64px;
				padding-left: 9px;
				font-size: 16px;
				font-weight: bold;
				background: url(../../../assets/img/cangku/detailName.png) no-repeat;
				background-position: left top;
				background-size: 16px 16px;
				margin-bottom: 20px;
			}

			.comView {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				font-size: 14px;

				.comItem {
					display: flex;
					align-items: center;
					height: 40px;

					.comItemName {
						width: 70px;
						text-align: right;
						margin-right: 8px;

						span {
							color: #FF3939;
						}
					}
				}

				.comTxt {
					color: #666;
					margin-left: 12px;

					span {
						color: #F77E04;
						cursor: pointer;
					}
				}
			}
		}

		.viewB {
			height: 68px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			box-sizing: border-box;
			border-top: solid 1px #F2F2F2;
		}

		.viewC::-webkit-scrollbar {
			display: none;
		}
	}

	.upload-cropper-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.cropper-upload-box {
		display: flex;
		overflow: hidden;

		.el-upload {
			width: 120px;
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
				height: 100%;
				max-height: 90%;
			}
		}
	}
}
</style>
